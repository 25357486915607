<template>
  <div class="loading-bar">
    <div
      class="percentage"
      :style="{'width' : percentage + '%'}"
    />
  </div>
</template>

<script>
export default {
    data: () => {
      return {
        percentage: 0
      }
    },
  created() {
    var intval = setInterval(() => {
      if(this.percentage < 100)
        this.percentage += 0.1;
        else if(this.percentage > 99)
        this.percentage = 0
      else
        clearInterval(intval);
    }, 1);
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/styles/variables.scss";

.loading-bar {
    position: relative;
    width: 400px;
    height: 30px;
    border-radius: 15px;
    overflow: hidden;
    border: 1px solid $green;
}

  .percentage {
    position: absolute;
    // top: 1px; left: 1px; right: 1px;
    display: block;
    height: 100%;
    border-radius: 15px;
    background-color: $green;
    background-size: 30px 30px;
    background-image: linear-gradient(135deg, rgba($color: white, $alpha: .15) 25%, transparent 25%,
      transparent 50%, rgba($color: white, $alpha: .15) 50%,
      rgba($color: white, $alpha: .15) 75%, transparent 75%,
      transparent); 
    animation: animate-stripes 3s linear infinite;
  } 

@keyframes animate-stripes {
  0% {background-position: 0 0; }
  100% { background-position: 60px 0;}
}
</style>