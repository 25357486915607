<template>
  <div class="card">
    <header class="card-header">
      <div
        v-if="hasHeaderIconSlot"
        class="card-header-title is-centered"
      >
        <slot name="iconheader" />
      </div>
      <p class="card-header-title is-centered has-text-centered has-text-light ">
        <slot name="header" />
      </p>
      <div
        v-if="hasHeaderTagSlot || hasHeaderIconSlot"
        class="card-header-title is-centered"
      >
        <slot name="tagheader" />
      </div>
    </header>
    <div class="card-content">
      <slot name="content" />
    </div>
    <footer
      
      class="card-footer"
    >
      <p
        v-if="hasFooterSlot"
        class="column is-12 has-text-centered has-text-light is-italic"
      >
        <slot name="footer" />
      </p>
    </footer>
  </div>
</template>

<script>
export default {
   computed: {
            
            hasFooterSlot() {
                return this.$slots['footer']
            },
            hasHeaderIconSlot() {
              return this.$slots['iconheader']
            },
             hasHeaderTagSlot() {
              return this.$slots['tagheader']
            },
        
        }
};
</script>

<style lang="scss" scoped>
.card {
  height: 100%;
  display: flex;
  flex-direction: column;
  // border-left: 2px solid $green;
}

.card-content {
  flex: auto;
}

p {
  font-size: 0.8em;
}
</style>